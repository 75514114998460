<template>
  <div
    v-if="loaded"
    class="progress-steps"
  >
    <h3 class="sl__h3 progress-title">
      Start a Business
    </h3>
    <h6 class="sl__h6 progress-subtitle">
      {{ subtitle }}
    </h6>
    <progress-bar :progress-status="progressStatus" />
  </div>
  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
export default {
  name: 'ProgressSteps',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    ProgressBar: () => import('@/components/StagelineV2/domainsFirst/shared/ProgressBar'),
  },
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    progressStatus: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      loaded: false,
    }
  },
  async mounted() {
    this.loaded = true
  },
}
</script>

<style lang="scss" scoped>
.progress-steps {
  margin-bottom: 2.0em;
  @include sl__flex(column, center, flex-start);

  .progress-title {
    margin-bottom: 0.5em;
  }

  .progress-subtitle {
    margin-bottom: 1.0em;
    text-transform: capitalize;
  }

  .progress-status {
    width: 100%;
    height: 2.0em;
  }
}
</style>
