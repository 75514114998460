var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "progress-steps" },
        [
          _c("h3", { staticClass: "sl__h3 progress-title" }, [
            _vm._v("\n    Start a Business\n  "),
          ]),
          _c("h6", { staticClass: "sl__h6 progress-subtitle" }, [
            _vm._v("\n    " + _vm._s(_vm.subtitle) + "\n  "),
          ]),
          _c("progress-bar", {
            attrs: { "progress-status": _vm.progressStatus },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }